import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg width={513} height={421} viewBox='0 0 513 421' fill='none' {...props}>
      <path
        d='M20.966 313.128V297.97c0-5.073-4.148-9.221-9.218-9.221h-2.46c-5.07 0-9.219 4.148-9.219 9.221v36.793H97.17v-21.635H20.966z'
        fill='#B1B1B1'
      />
      <path
        d='M432.519 193.915c0 8.146-6.601 14.749-14.75 14.749-8.146 0-14.749-6.603-14.749-14.749v-85.477c0-8.146 6.603-14.75 14.749-14.75 8.149 0 14.75 6.604 14.75 14.75v85.477z'
        fill='#D7D7D7'
      />
      <path
        d='M268.433 171.166c0-5.07 4.151-9.22 9.218-9.22h201.933c5.072 0 9.221 4.15 9.221 9.22v116.016c0 5.068-4.149 9.219-9.221 9.219H277.651c-5.067 0-9.218-4.151-9.218-9.219V171.166'
        fill='#F3F3F3'
      />
      <path
        d='M89.3 14.453c0-5.07 4.956-9.218 11.016-9.218h183.646c6.054 0 11.012 4.148 11.012 9.218v184.993c0 5.07-4.958 9.218-11.012 9.218H100.316c-6.06 0-11.016-4.148-11.016-9.218V14.453z'
        fill='#F3F3F3'
      />
      <path
        d='M262.446 208.702h-143.15V38.319h143.15v170.383z'
        fill='#E7E7E7'
      />
      <path
        d='M356.929 350.665h-81.735v-62.069h81.735v62.069z'
        fill='#B1B1B1'
      />
      <path
        d='M184.155 349.955c-26.266 0-47.559-21.285-47.559-47.552 0-26.259 21.293-47.56 47.559-47.56 26.265 0 47.552 21.301 47.552 47.56 0 26.267-21.287 47.552-47.552 47.552zm0-164.192c-64.414 0-116.637 52.223-116.637 116.64s52.223 116.635 116.637 116.635c64.415 0 116.638-52.218 116.638-116.635s-52.223-116.64-116.638-116.64zM412.59 369.557c-19.216 0-34.794-15.576-34.794-34.794 0-19.211 15.578-34.797 34.794-34.797 19.214 0 34.789 15.586 34.789 34.797 0 19.218-15.575 34.794-34.789 34.794zm0-120.126c-47.129 0-85.331 38.204-85.331 85.332 0 47.122 38.202 85.331 85.331 85.331 47.125 0 85.329-38.209 85.329-85.331 0-47.128-38.204-85.332-85.329-85.332z'
        fill='#D7D7D7'
      />
      <path
        d='M52.132 304.371c8.115 0 15.56-5.446 17.763-13.708 14.452-54 62.244-91.707 116.221-91.707 53.075 0 95.264 35.338 111.075 87.955 2.931 9.775 17.297 15.318 27.031 12.369 9.739-2.957 15.249-13.281 12.302-23.069-20.531-68.347-80.971-114.265-150.408-114.265-70.598 0-133.008 48.989-151.786 119.12-2.631 9.863 3.193 20.011 13.009 22.663 1.598.442 3.21.642 4.793.642'
        fill='#C2C2C2'
      />
      <path
        d='M325.737 325.803a12.049 12.049 0 0011.611-8.954c9.438-35.283 40.664-59.921 75.931-59.921 34.678 0 62.242 23.092 72.571 57.466 1.916 6.391 11.3 10.011 17.662 8.082 6.365-1.929 9.964-8.675 8.038-15.07-13.416-44.655-52.904-74.656-98.271-74.656-46.127 0-86.903 32.005-99.171 77.828-1.72 6.442 2.084 13.073 8.498 14.808 1.049.288 2.097.417 3.131.417'
        fill='#C2C2C2'
      />
      <path
        d='M306.73 8.868c0 4.725-4.578 8.557-10.228 8.557H85.465c-5.647 0-10.225-3.832-10.225-8.557 0-4.726 4.578-8.556 10.225-8.556h211.037c5.65 0 10.228 3.83 10.228 8.556z'
        fill='#A9A9A9'
      />
    </svg>
  )
}

export default SvgComponent
