import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg width={512} height={512} viewBox='0 0 228 299' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M145.034 146.919c-29.551 19.571 29.231 112.435 66.091 88.021 36.219-23.986-36.678-107.5-66.091-88.021z'
        fill='#D1D0D0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M141.041 146.919c-33.217 19.571 8.497 112.435 49.93 88.021 40.711-23.986-16.869-107.5-49.93-88.021z'
        fill='#C1C1C1'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M82.87 146.919c29.903 19.571-29.58 112.435-66.878 88.021-36.65-23.986 37.116-107.5 66.879-88.021z'
        fill='#D1D0D0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M86.356 146.919c32.766 19.571-9.859 112.435-50.734 88.021-40.162-23.986 18.118-107.5 50.734-88.021z'
        fill='#C1C1C1'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M113.625 130c96.999 0 119.846 152-1.149 152-118.886 0-95.4-152 1.149-152z'
        fill='#D1D0D0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M114 166c43.513 0 79-32.567 79-72.5 0-39.93-35.487-72.5-79-72.5S35 53.57 35 93.5c0 39.933 35.487 72.5 79 72.5z'
        fill='#C1C1C1'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.744 3.56C41.56-4.332 60.767 1.336 68.642 16.217c7.872 14.878 1.431 33.335-14.386 41.224-15.816 7.888-35.024 2.222-42.897-12.659C3.485 29.905 9.926 11.446 25.744 3.56z'
        fill='#D1D0D0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.535 19.16c9.392-4.787 20.796-1.349 25.47 7.675 4.676 9.026.85 20.222-8.541 25.007-9.392 4.784-20.795 1.348-25.47-7.678-4.674-9.025-.851-20.22 8.54-25.005'
        fill='#EEE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M202.534 3.56c-15.571-7.891-34.476-2.223-42.229 12.658-7.748 14.878-1.409 33.335 14.162 41.224 15.571 7.888 34.476 2.222 42.227-12.659 7.75-14.878 1.41-33.337-14.16-41.224z'
        fill='#D1D0D0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M192.467 19.16c-9.394-4.787-20.798-1.349-25.473 7.675-4.675 9.026-.85 20.222 8.542 25.007 9.393 4.784 20.794 1.348 25.469-7.678 4.676-9.025.851-20.22-8.538-25.005'
        fill='#EEE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M113.998 161C164.121 161 205 128.655 205 89c0-39.657-40.879-72-91.002-72C63.877 17 23 49.343 23 89c0 39.655 40.877 72 90.998 72z'
        fill='#D1D0D0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M150.736 165c22.172 31.096 16.748 87-36.734 87-53.486 0-58.91-55.904-36.738-87 11.224 3.331 23.654 5.185 36.738 5.185 13.08 0 25.51-1.854 36.734-5.185z'
        fill='#9B9B9B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M114 155c22.581 0 41-13.478 41-30.001C155 108.476 136.581 95 114 95s-41 13.476-41 29.999C73 141.522 91.419 155 114 155z'
        fill='#EEE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M113.999 126c11.567 0 21.001-6.514 21.001-14.499C135 103.514 125.566 97 113.999 97 102.432 97 93 103.514 93 111.501c0 7.985 9.432 14.499 20.999 14.499'
        fill='#000'
      />
      <path
        d='M109.501 112c-4.143 0-7.501-2.24-7.501-5 0-2.762 3.358-5 7.501-5 4.141 0 7.499 2.238 7.499 5 0 2.76-3.358 5-7.499 5z'
        fill='#636363'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M138.499 93c3.58 0 6.501-2.92 6.501-6.5 0-3.581-2.921-6.5-6.501-6.5-3.58 0-6.499 2.919-6.499 6.5 0 3.58 2.919 6.5 6.499 6.5zM89.5 93c3.579 0 6.5-2.92 6.5-6.5 0-3.581-2.921-6.5-6.5-6.5-3.58 0-6.5 2.919-6.5 6.5 0 3.58 2.92 6.5 6.5 6.5z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M190 299c20.929 0 38-18.644 38-41.5S210.929 216 190 216s-38 18.644-38 41.5 17.071 41.5 38 41.5'
        fill='#9B9B9B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M189.5 280c11.29 0 20.5-10.108 20.5-22.5 0-12.393-9.21-22.5-20.5-22.5-11.292 0-20.5 10.107-20.5 22.5 0 12.392 9.208 22.5 20.5 22.5z'
        fill='#D1D0D0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38 299c20.93 0 38-18.644 38-41.5S58.93 216 38 216 0 234.644 0 257.5 17.07 299 38 299'
        fill='#9B9B9B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.5 280c11.292 0 20.5-10.108 20.5-22.5 0-12.393-9.208-22.5-20.5-22.5-11.29 0-20.5 10.107-20.5 22.5 0 12.392 9.21 22.5 20.5 22.5z'
        fill='#D1D0D0'
      />
    </svg>
  )
}

export default SvgComponent
