import React, { useMemo } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

const Box = ({ authorID, title, description, image, path, date }) => {
  return (
    <>
      <div className='box'>
        <article className='media'>
          <div className='media-left is-clipped'>
            <Link to={`/review${path}`}>
              <figure className='image is-128x128'>
                <Img
                  fluid={image?.image?.childImageSharp?.fluid}
                />
              </figure>
            </Link>
          </div>
          <div className='media-content'>
            <div className='content'>
              <p>
                <strong>{title}</strong> <small>@{authorID}</small>{' '}
                <small>{date}</small>
                <br />
                {description?.slice(0, 130)}...
              </p>
              <Link to={`/review${path}`}>Read More</Link>
            </div>
            <nav className='level is-mobile'>
              <div className='level-left'>
                <a className='level-item' aria-label='reply'>
                  <span className='icon is-small'>
                    <i className='fas fa-reply' aria-hidden='true' />
                  </span>
                </a>
                <a className='level-item' aria-label='retweet'>
                  <span className='icon is-small'>
                    <i className='fas fa-retweet' aria-hidden='true' />
                  </span>
                </a>
                <a className='level-item' aria-label='like'>
                  <span className='icon is-small'>
                    <i className='fas fa-heart' aria-hidden='true' />
                  </span>
                </a>
              </div>
            </nav>
          </div>
        </article>
      </div>
    </>
  )
}

export default Box
