import React from 'react'
import CategorySection from '../components/CategorySection'
import ArticleSection from '../components/ArticleSection'
import SEO from '../components/seo/Seo'
import Layout from '../components/Layout'

const index = () => {
  return (
    <>
      <Layout isRoot={true}>
        <SEO />
        <CategorySection />
        <ArticleSection />
      </Layout>
    </>
  )
}
export default index
