import React from 'react'
import StuffedToy from '../svg/icons/StuffedToy'
import ActionFigure from '../svg/icons/ActionFigure'
import ToyCar from '../svg/icons/ToyCar'
import KokeshiDoll from '../svg/icons/KokeshiDoll'
import { Link } from 'gatsby'
const CategorySection = () => {
  return (
    <section className='hero is-link'>
      <div className='hero-body'>
        <div className='container has-text-centered'>
          <h1 className='title'>All The Fun!</h1>
          <h2 className='subtitle'>check out all our categories</h2>
        </div>
        <div className='container mt-6'>
          <div className='columns is-multiline'>
            <div className='column is-one-quarter has-text-centered'>
              <StuffedToy style={{ maxHeight: '85px', maxWidth: '85px' }} />
              <h6 className='is-size-6 is-italic'>STUFFED TOY</h6>
            </div>

            <div className='column is-one-quarter has-text-centered'>
              <ActionFigure style={{ maxHeight: '85px', maxWidth: '85px' }} />
              <h6 className='is-size-6 is-italic'>ACTION FIGURE</h6>
            </div>

            <div className='column is-one-quarter has-text-centered'>
              <ToyCar style={{ maxHeight: '85px', maxWidth: '85px' }} />
              <h6 className='is-size-6 is-italic'>TOY CAR</h6>
            </div>

            <div className='column is-one-quarter has-text-centered'>
              <KokeshiDoll style={{ maxHeight: '85px', maxWidth: '85px' }} />
              <h6 className='is-size-6 is-italic'>DOLL</h6>
            </div>
            <div className='column is-full has-text-centered'>
              <Link to='/categories'>
                <button className='button is-primary is-light'>BROWSE</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CategorySection
