import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg width={191} height={369} viewBox='0 0 191 369' fill='none' {...props}>
      <path
        d='M136.953 94.532L46.951 220.92a4.312 4.312 0 01-6.014 1.011l-.072-.051a4.312 4.312 0 01-1.01-6.013L129.857 89.48l7.096 5.052z'
        fill='#9B9B9B'
      />
      <path
        d='M63.753 106.149c-8.77-.613-17.869 1.278-27.229 5.164-14.968 4.798-27.907 10.307-34.933 17.883-1.115 2.728-.567 4.625 0 6.239l50.598 5.682 6.712-2.15 4.852-32.818'
        fill='#DDD'
      />
      <path
        d='M166.485 319.667l-1.012 7.953a19.421 19.421 0 00.362 6.901l1.69 7.187v17.259c.699 4.004 2.915 7.097 6.862 9.149 4.794.6 11.437-.095 14.348-4.159 2.43-4.169 2.432-8.719 2.116-13.827a23.983 23.983 0 00-1.494-6.961l-4.841-12.877-3.267-9.814-.624-4.762-14.14 3.951zM35.875 319.667l1.012 7.953c.293 2.304.17 4.642-.36 6.901l-1.692 7.187v17.259c-.698 4.004-2.914 7.097-6.862 9.149-4.794.6-11.437-.095-14.348-4.159-2.43-4.169-2.432-8.719-2.116-13.827.148-2.38.654-4.728 1.494-6.961l4.841-12.877 3.267-9.814.624-4.762 14.14 3.951z'
        fill='#888585'
      />
      <path
        d='M181.249 320.473c-1.738 1.714-3.518 2.936-5.348 3.611-3.185 1.189-6.521.732-10.04-1.615-9.672-13.257-17.474-26.082-17.466-37.129l-4.575-5.847c-14.888-18.09-40.189-65.708-42.643-70.349-2.446 4.641-27.748 52.259-42.636 70.349l-4.574 5.847c.008 11.047-7.794 23.872-17.467 37.129-3.868 2.579-7.519 2.879-10.988 1.207-1.497-.707-2.97-1.788-4.4-3.203-3.168-13.333-2.361-26.225 4.99-38.368l9.566-14.506 4.99-11.236c3.951-26.243 11.395-50.088 25.785-69.211l1.764-3.268 2.81-5.199h60.327l4.575 8.467a106.697 106.697 0 018.026 12.393c9.283 16.644 14.623 35.974 17.758 56.818l4.99 11.236 9.566 14.506c7.353 12.143 8.16 25.035 4.99 38.368'
        fill='#DDD'
      />
      <path
        d='M29.883 324.717c-1.49 0-2.946-.354-4.371-1.041l8.101-5.399c8.942-11.294 15.13-24.053 17.675-38.742l-9.632-9.774c2.621.688 5.157 1.148 7.519 1.148 2.757 0 5.274-.628 7.412-2.253l7.169-9.707c12.044-15.712 19.488-33.836 25.585-52.674 1.206-9.74-12.044-19.19-21.134-22.391l2.81-5.199c9.522 3.102 19.231 4.576 29.11 4.576 10.23 0 20.644-1.581 31.217-4.576l4.575 8.467a106.697 106.697 0 018.026 12.393c-7.489-.893-14.13-1.966-19.376-1.966-7.493 0-12.14 2.188-12.354 10.217 4.557 17.991 17.508 40.265 33.128 63.904l5.756 8.043c1.533 2.421 4.272 3.213 7.308 3.213 1.888 0 3.89-.307 5.792-.717l-8.592 5.546c.79 11.188 6.321 21.726 14.206 29.595 0 0 3.618 3.809 6.088 6.704a9.705 9.705 0 01-3.421.633c-2.136 0-4.339-.728-6.619-2.248-2.501-3.428-4.876-6.826-7.025-10.174-5.688-8.862-9.78-17.359-10.368-25.056a24.988 24.988 0 01-.073-1.866v-.033l-4.138-5.288-.437-.559a83.844 83.844 0 01-1.268-1.58c-15.012-19.132-38.991-64.257-41.375-68.769-1.885 3.576-17.34 32.669-31.145 54.109-3.467 5.386-6.83 10.287-9.848 14.18a87.766 87.766 0 01-1.643 2.06l-4.574 5.847v.033c-.004 5.172-1.718 10.735-4.532 16.55-3.192 6.593-7.8 13.508-12.935 20.546-2.279 1.52-4.483 2.248-6.617 2.248'
        fill='#B7B3B3'
      />
      <path
        d='M71.016 178.685l-12.727-41.726 4.436-30.498c13.123-4.084 25.976-4.964 38.455-1.386 13.108-.474 23.72 1.357 30.58 6.654 7.541 2.926 12.601 7.098 15.804 12.199l-16.22 54.757c-20.785 5.886-40.944 6.314-60.328 0'
        fill='#DDD'
      />
      <path
        d='M54.145 143.831l-4.402-2.988 2.446.274 4.107-1.316c-1.059 1.292-1.76 2.638-2.15 4.03'
        fill='#404040'
      />
      <path
        d='M52.19 141.117l-2.447-.274-18.567-12.6c10.228-.04 19.893-1.201 28.91-3.647l-1.797 12.362.566 1.854-2.264.643c-.102.114-.199.23-.295.346l-4.107 1.316z'
        fill='#B7B3B3'
      />
      <path
        d='M87.712 160.625l-8.89-.282-.651-.729-2.528-2.826c.029-.068.057-.137.084-.208l.029-.074 1.385-1.803 1.526-3.188c.506-1.147.602-2.226 0-3.188l.805-1.432c-.167-1.523-.428-3.043-1.13-3.971.813-1.531.17-3.262-1.926-4.472-1.512-.873-3.492-1.309-5.473-1.309-1.666 0-3.331.308-4.718.925h-4.748l-2.622.744-.566-1.853 1.798-12.363a86.467 86.467 0 0013.401-4.826l2.516 12.773c3.785 2.201 5.05 5.207 5.184 8.575 2.476 3.634 1.892 8.057 0 12.814.91 2.852 3.245 4.996 6.816 6.523l-.292.17'
        fill='#B7B3B3'
      />
      <path
        d='M100.127 183.261c-9.879 0-19.588-1.474-29.111-4.576l-.041-.202h3.5v-1.259l.66.005 5.622-1.33 3.111-.748 1.588-9.566-2.952-1.114-.468-.524 5.676-3.322 36.563 1.166s1.04-18.138 8.398-28.187l-1.329 45.081c-10.573 2.995-20.987 4.576-31.217 4.576'
        fill='#B7B3B3'
      />
      <path
        d='M82.036 163.947l-3.215-3.604 8.891.282-5.676 3.322'
        fill='#A9A0A0'
      />
      <path
        d='M68.447 177.04c-1.378.796-1.378 2.087 0 2.883 1.378.796 3.613.796 4.992 0 1.378-.796 1.378-2.087 0-2.883-1.379-.796-3.614-.796-4.992 0z'
        fill='#888585'
      />
      <path d='M67.413 178.483h7.061v-30.931h-7.06v30.931z' fill='#888585' />
      <path
        d='M67.413 175.676v1.412c.199.216.448.413.748.584.755.431 1.742.675 2.78.675 1.044 0 2.031-.244 2.779-.675.301-.171.556-.369.755-.584v-1.412c.022.544-.318 1.088-1.038 1.503-.686.402-1.589.601-2.496.601-.902 0-1.804-.199-2.496-.601-.714-.415-1.054-.959-1.026-1.503h-.006'
        fill='#888585'
      />
      <path
        d='M67.413 173.501v1.412c.199.216.448.415.748.584.755.432 1.742.675 2.78.675 1.044 0 2.031-.243 2.779-.675a3.05 3.05 0 00.755-.584v-1.412c.022.544-.318 1.09-1.038 1.503-.686.403-1.589.601-2.496.601-.902 0-1.804-.198-2.496-.601-.714-.413-1.054-.959-1.026-1.503h-.006'
        fill='#888585'
      />
      <path
        d='M67.413 171.327v1.412c.199.216.448.414.748.584.755.432 1.742.676 2.78.676 1.044 0 2.031-.244 2.779-.676.301-.17.556-.368.755-.584v-1.412c.022.544-.318 1.089-1.038 1.502-.686.403-1.589.602-2.496.602-.902 0-1.804-.199-2.496-.602-.714-.413-1.054-.958-1.026-1.502h-.006'
        fill='#888585'
      />
      <path
        d='M67.413 169.152v1.412c.199.216.448.415.748.586.755.43 1.742.674 2.78.674 1.044 0 2.031-.244 2.779-.674.301-.171.556-.37.755-.586v-1.412c.022.546-.318 1.09-1.038 1.504-.686.403-1.588.6-2.496.6-.902 0-1.804-.197-2.496-.6-.714-.414-1.054-.958-1.026-1.504h-.006'
        fill='#888585'
      />
      <path
        d='M67.413 166.977v1.414c.199.214.448.413.748.584.755.43 1.742.674 2.78.674 1.044 0 2.031-.244 2.779-.674.301-.171.556-.37.755-.584v-1.414c.022.546-.318 1.09-1.038 1.504-.686.403-1.589.602-2.496.602-.902 0-1.804-.199-2.496-.602-.714-.414-1.054-.958-1.026-1.504h-.006'
        fill='#888585'
      />
      <path
        d='M67.413 164.804v1.412c.199.216.448.413.748.584.755.431 1.742.675 2.78.675 1.044 0 2.031-.244 2.779-.675.301-.171.556-.368.755-.584v-1.412c.022.544-.318 1.088-1.038 1.503-.686.402-1.588.601-2.496.601-.902 0-1.804-.199-2.496-.601-.714-.415-1.054-.959-1.026-1.503h-.006'
        fill='#888585'
      />
      <path
        d='M67.413 162.63v1.412c.199.216.448.414.748.584.755.432 1.742.674 2.78.674 1.044 0 2.031-.242 2.779-.674.301-.17.556-.368.755-.584v-1.412c.022.544-.318 1.089-1.038 1.502-.686.403-1.588.602-2.496.602-.902 0-1.804-.199-2.496-.602-.714-.413-1.054-.958-1.026-1.502h-.006'
        fill='#888585'
      />
      <path
        d='M67.413 160.455v1.412c.199.216.448.415.748.584.755.432 1.742.676 2.78.676 1.044 0 2.031-.244 2.779-.676a3.05 3.05 0 00.755-.584v-1.412c.022.544-.318 1.089-1.038 1.503-.686.402-1.588.601-2.496.601-.902 0-1.804-.199-2.496-.601-.714-.414-1.054-.959-1.026-1.503h-.006'
        fill='#888585'
      />
      <path
        d='M67.413 158.28v1.412c.199.216.448.415.748.585.755.431 1.742.675 2.78.675 1.044 0 2.031-.244 2.779-.675.301-.17.556-.369.755-.585v-1.412c.022.545-.318 1.089-1.038 1.503-.686.404-1.588.602-2.496.601-.902 0-1.804-.197-2.496-.601-.714-.414-1.054-.958-1.026-1.503h-.006'
        fill='#888585'
      />
      <path
        d='M67.413 156.105v1.414c.199.214.448.413.748.584.755.43 1.742.674 2.78.674 1.044 0 2.031-.244 2.779-.674.301-.171.556-.37.755-.584v-1.414c.022.546-.318 1.09-1.038 1.504-.686.403-1.589.602-2.496.602-.902 0-1.804-.199-2.496-.602-.714-.414-1.054-.958-1.026-1.504h-.006'
        fill='#888585'
      />
      <path
        d='M67.413 153.932v1.412c.199.216.448.413.748.584.755.431 1.742.675 2.78.675 1.044 0 2.031-.244 2.779-.675.301-.171.556-.369.755-.584v-1.412c.022.544-.318 1.088-1.038 1.503-.686.402-1.588.601-2.496.601-.902 0-1.804-.199-2.496-.601-.714-.415-1.054-.959-1.026-1.503h-.006'
        fill='#888585'
      />
      <path
        d='M67.413 151.757v1.412c.199.216.448.415.748.584.755.432 1.742.676 2.78.676 1.044 0 2.031-.244 2.779-.676a3.05 3.05 0 00.755-.584v-1.412c.022.544-.318 1.09-1.038 1.503-.686.403-1.589.601-2.496.601-.902 0-1.804-.198-2.496-.601-.714-.413-1.054-.959-1.026-1.503h-.006'
        fill='#888585'
      />
      <path
        d='M67.413 149.583v1.412c.199.216.448.415.748.584.755.432 1.742.676 2.78.676 1.044 0 2.031-.244 2.779-.676a3.05 3.05 0 00.755-.584v-1.412c.022.544-.318 1.089-1.038 1.503-.686.402-1.588.601-2.496.601-.902 0-1.804-.199-2.496-.601-.714-.414-1.054-.959-1.026-1.503h-.006'
        fill='#888585'
      />
      <path
        d='M68.447 146.113c-1.378.796-1.378 2.086 0 2.882 1.378.796 3.613.796 4.992 0 1.378-.796 1.378-2.086 0-2.882-1.379-.796-3.614-.796-4.992 0z'
        fill='#888585'
      />
      <path
        d='M1.59 135.435l20.417-10.592c12.022 3.105 21.294 11.36 34.584 14.612-2.895 4.11-3.319 8.55-1.352 13.308-24.455-3.342-47.572-7.178-53.648-17.328'
        fill='#DDD'
      />
      <path
        d='M85.456 165.585l-1.588 9.566-3.11.748-5.623 1.33s-8.184-.066-8.518 0c-.332.067-5.414-2.877-5.414-2.877-1.19-1.297-1.772-2.62-1.19-4.009-.898-1.09-1.23-2.171-.549-3.236-1.039-1.563-1.172-2.911 0-3.951-.673-1.713-.84-3.219.416-4.159l2.912-2.702h12.41l2.969 3.318 4.333 4.858 2.952 1.114'
        fill='#9E9E9E'
      />
      <path
        d='M70.097 162.707c-.317 0-.642-.014-.976-.042l-9.241-3.668 1.43-1.328.167.084 5.684 2.218c1.156.204 2.216.301 3.184.301.103 0 .206-.001.306-.003 2.201-.048 4.134-1.464 4.992-3.481l2.528 2.825c-2.312 1.668-4.798 3.094-8.074 3.094'
        fill='#CDCAC9'
      />
      <path
        d='M75.756 156.505l1.385-1.802 1.526-3.188c.506-1.147.602-2.226 0-3.188l.805-1.432c-.208-1.888-.559-3.772-1.715-4.53h-.754c.349-1.317.233-2.464-.82-3.266l-2.784-1.031v2.529c-.624 2.046-4.852 1.63-5.407 0v-2.529h-6.515l-4.886 1.387c-3.491 3.986-3.502 8.488-1.352 13.308 1.393 2 3.528 3.636 6.238 4.99l5.684 2.218c1.28.225 2.44.321 3.49.298 2.276-.05 4.265-1.561 5.076-3.689l.029-.075'
        fill='#9E9E9E'
      />
      <path
        d='M78.343 142.924a2.371 2.371 0 00-.586-.559h-.754c.349-1.317.233-2.464-.82-3.266l-2.784-1.031v2.529c-.295.966-1.392 1.383-2.527 1.383-1.27 0-2.587-.523-2.88-1.383v-2.529h-1.767c1.387-.617 3.052-.925 4.718-.925 1.981 0 3.961.436 5.473 1.309 2.096 1.209 2.739 2.941 1.927 4.472'
        fill='#484848'
      />
      <path
        d='M70.943 146.079c-1.98 0-3.962-.436-5.472-1.308-3.023-1.746-3.023-4.575 0-6.32.24-.139.493-.267.754-.383h1.767v2.529c.293.86 1.61 1.383 2.88 1.383 1.135 0 2.232-.417 2.527-1.383v-2.529l2.784 1.031c1.053.802 1.169 1.949.82 3.266h.754c.223.147.418.336.586.559-.359.676-1.002 1.312-1.927 1.847-1.512.872-3.492 1.308-5.473 1.308'
        fill='#CDCAC9'
      />
      <path
        d='M82.132 164.407c-2.645 3.444-2.89 7.284-1.663 11.367 21.82 9.524 43.176 15.164 63.767 14.417 12.151-7.149 10.7-28.232 11.368-47.144-.491-8.377-2.935-14.967-8.04-19.119-12.661 1.911-18.184 10.326-17.191 24.676l-.553 19.684c.047-1.685-31.29-1.048-47.688-3.881'
        fill='#DDD'
      />
      <path
        d='M65.47 136.025c-3.022 1.745-3.022 4.575 0 6.319 3.023 1.745 7.923 1.745 10.946 0 3.021-1.744 3.021-4.574 0-6.319-3.023-1.745-7.923-1.745-10.945 0z'
        fill='#878787'
      />
      <path
        d='M67.533 139.491h6.812c-3.108-44.935 2.052-93.612 6.546-134.36L74.695.769c-4.982 40.666-9.65 92.504-7.162 138.722z'
        fill='#B2B2B2'
      />
      <path
        d='M74.695.77l1.309.922c-4.939 40.525-9.519 91.928-7.048 137.799h-1.423C65.045 93.273 69.713 41.435 74.695.769'
        fill='#DADADA'
      />
      <path
        d='M118.033 106.084c-3.917-.741-8.268-1.08-12.988-1.08-1.261 0-2.552.024-3.865.071-5.328-1.527-10.724-2.243-16.18-2.243-1.24 0-2.484.037-3.73.111.403-7.136 2.331-12.695 5.689-16.29 3.017-3.23 7.105-4.869 12.153-4.869.423 0 .856.012 1.295.035l.058.83.064-.83c.366-.023.798-.035 1.222-.035 5.046 0 9.134 1.639 12.152 4.87 3.496 3.743 5.441 9.617 5.732 17.181l-1.602 2.249z'
        fill='#404040'
      />
      <path
        d='M119.671 106.423a54.368 54.368 0 00-1.638-.339l1.602-2.249c.033.842.045 1.705.036 2.588z'
        fill='#717171'
      />
      <path
        d='M100.457 141.117h-.053c-1.563-.05-3.163-.393-3.984-.853-4.47-2.504-9.468-8.799-10.727-13.504-.354-.842-3.848-9.281-4.356-16.692a56.95 56.95 0 01-.068-7.125 62.96 62.96 0 013.731-.111c5.456 0 10.852.716 16.18 2.243 1.313-.047 2.604-.071 3.865-.071 4.72 0 9.071.339 12.988 1.08.555.105 1.1.219 1.638.339a62.388 62.388 0 01-.146 3.645c-.508 7.411-4.001 15.852-4.356 16.692-1.261 4.705-6.257 11-10.728 13.504-.821.46-2.421.803-3.984.853'
        fill='#B7B3B3'
      />
      <path
        d='M100.404 132.543c-1.563-.051-3.163-.393-3.984-.853-4.47-2.504-9.468-8.799-10.727-13.504-.354-.842-3.848-9.283-4.356-16.692-.726-10.603 1.218-18.699 5.622-23.415 3.017-3.232 7.105-4.87 12.153-4.87.423 0 .856.013 1.295.035l.058.831.064-.83c.366-.023.798-.035 1.222-.035 5.046 0 9.134 1.637 12.152 4.869 4.404 4.716 6.348 12.812 5.622 23.415-.508 7.409-4.001 15.852-4.356 16.692-1.261 4.705-6.257 11-10.728 13.504-.821.46-2.421.802-3.984.853h-.053'
        fill='#DDD'
      />
      <path
        d='M90.995 105.341c7.292 1.183 11.493 1.344 18.825.032 3.641-.818 3.783 6.647-.688 7.478-6.711 1.46-11.375 1.628-17.532.037-4.387-.847-4.963-8.125-.605-7.547'
        fill='#9E9E9E'
      />
      <path
        d='M125.067 108.04c.253 16.787-12.239 34.883-24.804 52.985l7.102.227c13.114-17.225 22.706-34.248 22.687-50.717l-4.985-2.495z'
        fill='#9B9B9B'
      />
    </svg>
  )
}

export default SvgComponent
