import React from 'react'
import Box from './fragments/Box'
import { useStaticQuery, graphql, Link } from 'gatsby'

const ArticleSection = () => {
  const data = useStaticQuery(graphql`
    query allforArticleSection {
      allGoogleDocs(limit: 6, sort: { fields: createdTime, order: DESC }) {
        nodes {
          name
          authorID
          description
          createdTime(fromNow: true)
          path
          cover {
            image {
              childImageSharp {
                fluid(maxWidth: 128) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <section className='hero is-success is-bold'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <h1 className='title'>Read More</h1>
            <h2 className='subtitle'>read through all our articles</h2>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <div className='columns is-multiline'>
            {data.allGoogleDocs.nodes.map((e, i) => (
              <div key={i} className='column is-half'>
                <Box
                  title={e.name}
                  authorID={e.authorID}
                  description={e.description}
                  image={e.cover}
                  path={e.path}
                  date={e.createdTime}
                />
              </div>
            ))}
            <div className='column is-full has-text-centered'>
              <Link to='/reviews'>
                <button className='button is-success is-dark'>BROWSE</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ArticleSection
